import { Auth } from 'aws-amplify';
import { history } from '../history';
import { IChangePasswordForm } from '../../interfaces/Forms';
import { ISuccess } from '../../interfaces';

export const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>, values: IChangePasswordForm) => {
    e.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const {oldPassword, newPassword} = values;
    const changePassword = await cognitoChangePassword(user, oldPassword, newPassword);
    if (changePassword.success) {
        history.push('/auth/change-password-confirm')
    } else {
        window.location.reload();
    }
}

const cognitoChangePassword = async (user: any, oldPassword: string, newPassword: string) => {
    const changePassword: ISuccess = {'success': null, 'error': null}
    try {
        const success = await Auth.changePassword(user, oldPassword, newPassword);
        changePassword.success = success;
    }catch(error){
        let err = null;
        !error.message ? err = {'message': error} : err = error;
        changePassword.error = err;
    }
    return changePassword;
}