import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState, useRef } from 'react';
import { IFetchData } from '../interfaces/Fetch';
import { AuthUser } from '../types';
import { Auth } from 'aws-amplify';

function useFetch<T>(endpoint: string, requestOptions?: AxiosRequestConfig): IFetchData<T> {
  const isCurrent = useRef(true);
  const [state, setState] = useState<IFetchData<T>>({data: [], loading: true});
  const [user, setUser] = useState<AuthUser | undefined | null>(undefined);

  let baseUrl = 'http://127.0.0.1:5000';
  let key = '';
  if (user){
    if (user.pool.userPoolId === "us-east-1_cpNOGfBBC") {
      const groups = user.groups ?? ["TEST"]
      baseUrl = `https://ehk21rdymb.execute-api.us-east-1.amazonaws.com/prod/properties/${groups[0]}`;
      key = 'qoEjpseTql6x3tn4B8o9O86yllbr8LQK8oCYLSu1';
    } else {
      baseUrl = 'https://h63qjepqjh.execute-api.us-east-1.amazonaws.com/dev/properties/TEST';
      key = '4uobsp5mSI1gXEWOiJMLDaWRgMxyrIFBafymRZLE';
    }  
  }
  const options: AxiosRequestConfig = requestOptions ? requestOptions : {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': key ?? ''
    },
  };

  const refreshData = async () => {
    setState((s: IFetchData<T>) => ({...s, loading: true}))
    try {
      const result = await fetch(baseUrl + endpoint, options) 
      const res = await result.json()
      if (isCurrent.current){
        setState({data: res, loading: false});
      };  
    }catch(error){
    }
  }

  useEffect(() => {
    const getAuthorizedUser = async () => {
      const session = await Auth.currentSession();
      const groups = session.getAccessToken().decodePayload()['cognito:groups'];
      const user: AuthUser = await Auth.currentAuthenticatedUser() as AuthUser;
      user.groups = groups;
      setUser(user)
    };
    getAuthorizedUser();
    return () => {
      isCurrent.current = false;
    }
  }, [])

  useEffect(() => {

    const fetchAsync = async () => {
      try {
        const result = await axios.get(baseUrl + endpoint, options) 
        const res = await result.data
        if (isCurrent.current){
          setState({data: res, loading: false});
        };  
      }catch(error){
        console.log(error)
      }
    };
    fetchAsync();
    return () => {}
    // eslint-disable-next-line
  }, [endpoint, requestOptions, baseUrl, key]);

  return {data: state.data, loading: state.loading, refresh: refreshData};
}

export default useFetch;