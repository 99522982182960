import React from 'react';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4)
  }
});

interface FooterProps extends WithStyles<typeof styles>{
  className?: string
}

const Footer: React.FC<FooterProps> = props => {
  const { classes, className, ...rest } = props;

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    </div>
  );
};

export default withStyles(styles)(Footer);
