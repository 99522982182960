import React from 'react';
import './assets/styles/index.css';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { Router } from 'react-router-dom';
import { history } from './helpers/history';
import Routes from './Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

const App: React.FC = () => {


  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <Routes />
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
