import React, { useEffect, useState } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { listUsers } from '../../helpers/auth/listUsers';
import { IUserProfile } from '../../interfaces/User';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4)
  }
});

interface UsersProps extends WithStyles<typeof styles>{
  className?: string,
}

const Users: React.FC<UsersProps> = (props) => {
  const { classes } = props;
  const [users, setUsers] = useState<Array<IUserProfile>>([]);
  const [loading, setLoading] = useState(true);

  const userAttrs = ['email', 'custom:Role', 'custom:EmployeeId', 'custom:JobTitle']

  useEffect(() => {
    (async function loadUsers(){
      const limit = '10';
      const userList = await listUsers(limit);
      setUsers(userList);  
    })();
    setLoading(false);
    return () => {
      setLoading(true);
    }
  }, []);

  const userGrid = !users
  ? <div></div>
  : <Table>
      <TableHead>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell align="left">Created Date</TableCell>
          <TableCell align="left">Last Updated</TableCell>
          <TableCell align="left">Email</TableCell>
          <TableCell align="left">Role</TableCell>
          <TableCell align="left">Employee ID</TableCell>
          <TableCell align="left">Job Title</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {
      users.map((user, index) => (
        <TableRow key={index}>
          <TableCell>{user.Username}</TableCell>
          <TableCell>{user.UserCreateDate}</TableCell>
          <TableCell>{user.UserLastModifiedDate}</TableCell>
          {user.Attributes.map((attr, i) => {
            if (userAttrs.includes(attr.Name)) {
              return <TableCell>{attr.Value}</TableCell>
            } else {
              return <></>
            }
          })}
        </TableRow>
      ))
    }
      </TableBody>
    </Table>

  const content = loading
  ? <div>Loading...</div>
  : <div>{userGrid}</div>

  return (
    <div className={classes.root}>
      {content}
    </div>
  )
}

export default withStyles(styles)(Users);