import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './components/routes/RouteWithLayout';
import AuthRouteWithLayout from './components/routes/AuthRouteWithLayout';
import Login from './views/Auth/Login';
import { MinimalLayout, MainLayout } from './layouts';
import Home from './views/Home';
import { Messages } from './views/Messages';
import ChangePassword from './views/Auth/ChangePassword';
import ChangePasswordConfirm from './views/Auth/ChangePasswordConfirm';
import ForgotPassword from './views/Auth/ForgotPassword';
import ForgotPasswordVerification from './views/Auth/ForgotPasswordVerification';
import NotFound from './views/NotFound';
import Users from './views/Auth/Users';


const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/messages" />
        <AuthRouteWithLayout 
            component={Home}
            exact
            layout={MainLayout}
            path={'/home'}
        />
        <AuthRouteWithLayout 
            component={Messages}
            exact
            layout={MainLayout}
            path={'/messages'}
        />
        <AuthRouteWithLayout 
            component={Users}
            exact
            layout={MainLayout}
            path={'/users'}
        />
        <AuthRouteWithLayout 
            component={ChangePasswordConfirm}
            exact
            layout={MainLayout}
            path={'/auth/change-password-confirm'}
        />
        <AuthRouteWithLayout 
            component={ChangePassword}
            exact
            layout={MainLayout}
            path={'/auth/change-password'}
        />
        <RouteWithLayout 
            component={ChangePassword}
            exact
            layout={MinimalLayout}
            path={'/auth/forced-change-password'}
        />
        <RouteWithLayout 
            component={Login}
            exact
            layout={MinimalLayout}
            path={'/auth/login'}
        />
        <RouteWithLayout 
            component={ForgotPassword}
            exact
            layout={MinimalLayout}
            path={'/auth/forgot-password'}
        />
        <RouteWithLayout 
            component={ForgotPasswordVerification}
            exact
            layout={MinimalLayout}
            path={'/auth/verify-account'}
        />
        <RouteWithLayout 
            component={NotFound}
            exact
            layout={MinimalLayout}
            path={'/not-found'}
        />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
