import React from 'react';
import { Theme, createStyles, WithStyles, withStyles, LinearProgress } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  loader: {
    margin: theme.spacing(3)
  }
});

interface LoaderProps extends WithStyles<typeof styles>{
  className?: string
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { classes } = props
  return <LinearProgress className={classes.loader}/>
}

export default withStyles(styles)(Loader);