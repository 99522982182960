/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9ce47fa3-42c7-4cfe-9d25-d4838861fee5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_cpNOGfBBC",
    "aws_user_pools_web_client_id": "1kd54ghfpuki84n9rgpv9h8lfd",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://uy548brwv3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "interlace-audio-content114957-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
