import React from 'react';
import { IMessage } from '../../../interfaces/Message';
import { Typography, Input, makeStyles, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px'
  },
  header: {
    margin: 20
  },
  input: {
    margin: 20
  },
  reviewLine: {
    margin: 20
  },
  defaultInput: {
    marginLeft: 20
  }
}))

interface ReviewMessageProps {
  update: (message: IMessage) => void
  message: IMessage
}

export const ReviewMessage: React.FC<ReviewMessageProps> = (props) => {
  const classes = useStyles();
  const {update, message} = props

  const messagePreview = (() => {
    switch(message.audio_type) {
      case "TEXT":
        return message.text;
      case "AUDIO":
        const audioFileName = message.audio?.name
        return audioFileName
      default:
        return "Unknown Audio Type"
    }
  })();

  return(
    <div className={classes.root}>
      <Typography variant={"h1"} className={classes.header}>Review Message Details</Typography>
      <div className={classes.input}>
        <Typography variant={"h3"}>Message Title</Typography>
        <Input onChange={e=>update({...message, name: e.target.value})}/>
      </div>
      <Typography className={classes.reviewLine} variant={"h4"}>Message Type: {message.message_type}</Typography>
      <Typography className={classes.reviewLine} variant={"h4"}>Audio Type: {message.audio_type}</Typography>
      <Typography className={classes.reviewLine} variant={"h4"}>Message: {messagePreview}</Typography>
      <div className={classes.defaultInput}>
        <Typography variant={"body1"}>Make this my current default for {message.message_type} message</Typography>
        <Checkbox checked={message.default} color="primary" onChange={e => update({...message, default: !message.default})} />
      </div>
    </div>
  )
}