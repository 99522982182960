import React from 'react';
import clsx from 'clsx';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import { AuthUser } from '../../../../types';

const styles = (theme: Theme) => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content'
    },
    avatar: {
      width: 60,
      height: 60
    },
    name: {
      marginTop: theme.spacing(1)
    }
  });

interface ProfileProps extends WithStyles<typeof styles>{
  className?: string,
  user?: AuthUser | null | undefined
}


const Profile: React.FC<ProfileProps> = props => {
  const { classes, className, ...rest } = props;
  const {user} = props;

  const userDisplay = (() => {
      if (user) {
        return <Typography variant="body2">{user.attributes.email}</Typography>
      } else {
        return <Typography variant="body2">No User</Typography>
      }
  })();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {userDisplay}
    </div>
  );
};


export default withStyles(styles)(Profile);
