import React from 'react';
import { IMessage } from '../../../interfaces/Message';
import { MessageType } from '../../../types';
import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px'
  },
  header: {
    margin: 20
  },
  button: {
    margin:15
  }
}))

interface CMessageTypeProps {
  update: (message: IMessage) => void
  message: IMessage
}

export const CMessageType: React.FC<CMessageTypeProps> = (props) => {
  const classes = useStyles();
  const {update, message} = props

  const onButtonClick = (mt: MessageType) => {
    update({...message, message_type: mt})
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} className={classes.header}>Message Type: {message.message_type}</Typography>
      <Button className={classes.button} onClick={e => onButtonClick("WELCOME")}>Welcome</Button>
      <Button className={classes.button} onClick={e => onButtonClick("GOODBYE")}>Goodbye</Button>
    </div>
  )
}