import React from 'react';
import { WithStyles, withStyles, Typography } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { IChangePasswordForm } from '../../interfaces/Forms';
import { handleChangePassword } from '../../helpers/auth/handleChangePassword';
import { formStyles } from '../../assets/ts-styles/form';


interface ChangePasswordProps extends WithStyles<typeof formStyles>{
  className?: string,
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const {values, handleChange} = useForm<IChangePasswordForm>({oldPassword:'', newPassword:''});
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography variant='h2'>Change Password</Typography>
      <form onSubmit={e=> handleChangePassword(e, values)}>
        <label className={classes.label}>Old Password</label>
        <input
          className={classes.input}
          name="oldPassword"
          type="password"
          value={values.oldPassword}
          onChange={handleChange}
        />
        <label className={classes.label}>New Password</label>
        <input
          className={classes.input}
          name="newPassword"
          type="password"
          value={values.newPassword}
          onChange={handleChange}
        />
        <input className={classes.submitButton} type="submit" value="Change Password" />
      </form>
    </div>
  )
}

export default withStyles(formStyles)(ChangePassword);