import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, CircularProgress } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { CAudioType } from './CAudioType';
import { CMessageType } from './CMessageType';
import { MessageContent } from './MessageContent';
import { ReviewMessage } from './ReviewMessage';
import { Storage } from 'aws-amplify'
import { IMessage } from '../../../interfaces/Message';
import { postInterlaceData } from '../../../helpers/api/post-data';

interface StorageObject {
  key: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

function getSteps() {
  return ['Message Type', 'Create your Message', 'Review Your Message'];
}

function getStepContent(step: number, change: (message: IMessage) => void ,message: IMessage) {
  switch (step) {
    case 0:
      return <CMessageType update={change} message={message}/>;
    case 1:
      return <MessageContent update={change} message={message}/>
    case 2:
      return <ReviewMessage update={change} message={message} />
    default:
      return 'Unknown step';
  }
}

interface CreateMessageProps {
  completed: (refresh?: boolean) => void
}

export const CreateMessage: React.FC<CreateMessageProps> = (props) => {
  const {completed} = props
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [ upload, setUpload ] = useState(false)
  const steps = getSteps();
  const [ message, setMessage ] = useState<IMessage>({default: true, audio_type:"TEXT"})

  const updateMessage = (message: IMessage) => {
    setMessage(message)
  }

  const isStepOptional = (step: number) => {
    return null;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const startUpload = () => {
    setUpload(true)
  }

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 1){
      try {
        if (message.audio_type === "AUDIO" && message.audio){
          startUpload()
          const result: any = await Storage.put(`INTERLACE/${message.audio.name}`, message.audio, {
            contentType: message.audio.type,
            customPrefix: 'INTERLACE/'
          })
          message.audio_link = result.key
        }
      } catch (error) {
        console.log(error)
      }
      await postInterlaceData<IMessage>('/messages', message)
      setUpload(false)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const backToMessages = () => {
    completed(true)
  }

  const cantGoToNextStep = (activeStep: number): boolean => {
    let canNotProgress = true
    switch (activeStep){
      case 0:
        canNotProgress = Boolean(!message.audio_type)
        break;
      case 1:
        canNotProgress = Boolean(!message.message_type)
        break;
      case 2:
        if (message.audio_type === "AUDIO") {
          canNotProgress = Boolean(!message.audio)
        } else {
          canNotProgress = Boolean(!message.text)

        }
        break;
      case 3:
        canNotProgress = Boolean(!message.name)
        break;
      default:
        canNotProgress = false
        break;
    }
    return canNotProgress
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              <Button onClick={backToMessages}>Back To Messages</Button>
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep, updateMessage, message)}</div>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={cantGoToNextStep(activeStep) && upload}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
              {upload && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
