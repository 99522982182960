import axios, { AxiosRequestConfig } from 'axios';

const PROP_CODE = "TEST"
const baseUrl = 'https://h63qjepqjh.execute-api.us-east-1.amazonaws.com/dev';
// const baseUrl = 'http://localhost:5000'
const key = 'bsBKA7UQV56wqFasAIqh41LuNh7MBjN03z1ty5zQ';
const options: AxiosRequestConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': key
  },
};


export const postInterlaceData = async <T> (endpoint: string, data: T) => {
  await axios.post(`${baseUrl}/properties/${PROP_CODE}${endpoint}`, data, options)
}
