import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Theme, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { handleLogOut } from '../../../helpers/auth/handleLogOut';


const styles = (theme: Theme) => createStyles({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
});

interface TopbarProps extends WithStyles<typeof styles>{
  className?: string,
  onSidebarOpen: () => void
}

const Topbar: React.FC<TopbarProps> = (props) => {
  const { classes, className, onSidebarOpen, ...rest } = props;

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        {/* <RouterLink to="/"> */}
        <img
          src={"./img/InterlaceLogo.png"}
          height={50}
          width={40}
          alt="logo"
        />
          {/* <Typography>Home</Typography> */}
        {/* </RouterLink> */}
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Tooltip title="Sign Out">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={ e => handleLogOut(e) }
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};


export default withStyles(styles)(Topbar);
