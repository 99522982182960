import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import './helpers/env'
import awsmobile from './aws-exports'

declare global {
  interface Window {LOG_LEVEL: string}
}

window.LOG_LEVEL = "DEBUG"
Amplify.configure(awsmobile)

// AWS.config.region = "us-east-1"
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: "us-east-1:a46267e0-02f8-4d8a-9474-2e99a6b38f22"})

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
