import React, {useState} from 'react';
import { IMessage } from '../../../interfaces/Message';
import { Input, makeStyles, FormLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px'
  },
  header: {
    margin: 20
  },
  label: {
    margin: 10
  },
  input: {
    margin:15,
    width: "75%"
  }
}))


interface MessageContentProps {
  update: (message: IMessage) => void
  message: IMessage
}

export const MessageContent: React.FC<MessageContentProps> = (props) => {
  const classes = useStyles();
  const [error, setError] = useState("")
  const {update, message} = props

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault()
    update({...message, text: e.target.value})
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setError("")
    if (e.target.files){
      const file = e.target.files[0]
      if (file.type !== "audio/mpeg" && !file.name.includes('mp3')) {
        setError("File is required to be an MP3")
      } else {
        update({...message, audio: e.target.files[0]})
      }
    }
  }

  return (
    <div className={classes.root}>
      {
        message.audio_type === "TEXT"
        ? <div>
            <FormLabel className={classes.label}>Message Text:</FormLabel>
            <Input multiline className={classes.input} onChange={e => onTextChange(e)} />
          </div>
        : <div>
            <label>Audio</label>
            <input type="file" onChange={e=>onFileChange(e)}/>
            {error}
          </div>
      }
    </div>
  )
}