import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const styles = () => createStyles({
  root: {
    boxShadow: 'none'
  }
})

interface TopbarProps extends WithStyles<typeof styles>{
  className?: string
}

const Topbar: React.FC<TopbarProps> = (props) => {
  const { classes, className } = props
  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <Typography>Home</Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Topbar);
