import { Auth, API } from 'aws-amplify';

export const listUsers = async (limit: string, token?: string) => {
    try {
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        const jwtToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
        let myInit = {
            queryStringParamaters: {
              "limit": limit,
              "token": token
            }, 
            headers: {
              'Content-Type' : 'application/json',
              'Authorization': jwtToken
            } 
        }

        const {NextToken, ...rest} = await API.get(apiName, path, myInit);
        // if (NextToken){
        //     const userSet = await listUsers(limit, NextToken);
        //     rest.push(userSet);
        // }
        const users = rest['Users']
        return users;
    } catch(error) {
        console.log(error);
    }
}