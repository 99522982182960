import React, { useState } from 'react';
import { WithStyles, withStyles, Typography, Link } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { ILoginForm } from '../../interfaces/Forms';
import { handleLogin } from '../../helpers/auth/handleLogin';
import { formStyles } from '../../assets/ts-styles/form';


interface LoginProps extends WithStyles<typeof formStyles>{
  className?: string,
}

const Login: React.FC<LoginProps> = (props) => {
  const {values, handleChange} = useForm<ILoginForm>({username:'', password:'', newPassword: ''})
  const [error, setError] = useState<any>(null);
  const { classes } = props

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    const error = await handleLogin(e ,values);
    if (error) {
      setError(error);
    }
  }

  const forceNewPassword = (() => {
    if (error?.message === 'reset_password'){
      return (<>
      <Typography style={{marginTop:'15px'}} variant='h4'>Please create a new password</Typography>
      <label className={classes.label}>New Password</label>
      <input
        className={classes.input}
        name="newPassword"
        type="password"
        value={values.newPassword}
        onChange={handleChange}
      />
      </>)
    }else{
      return <div></div>
    }
  })();

  return (
    <div className={classes.root}>
      <Typography variant='h2'>Sign In</Typography>
      <form onSubmit={async (e) => login(e)}>
        <label className={classes.label}>Username</label>
        <input
          className={classes.input}
          name="username"
          value={values.username}
          onChange={handleChange}
        />
        <label className={classes.label}>Password</label>
        <input
          className={classes.input}
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
        />
        {forceNewPassword}
        <input className={classes.submitButton} type="submit" value="LOGIN" />
      </form>
      <Typography>{error?.message ?? ''}</Typography>
      <Link href={'/auth/forgot-password'}>Forgot Password?</Link>
    </div>
  )
}

export default withStyles(formStyles)(Login);