import { Auth } from 'aws-amplify';
import { history } from '../history';
import { IForgotPasswordForm, IForgotPasswordSubmitForm } from '../../interfaces/Forms';
import { ISuccess } from '../../interfaces';

export const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>, values: IForgotPasswordForm) => {
    e.preventDefault();
    const {username} = values;
    const forgotPassword = await cognitoForgotPassword(username);
    if (forgotPassword.success){
        history.push('/auth/verify-account')
    }else{
        window.location.reload();
    }
}

export const handleForgotPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>, values: IForgotPasswordSubmitForm) => {
    e.preventDefault();
    const {username, code, password} = values;
    const forgotPassword = await cognitoForgotPasswordSubmit(username, code, password);
    if (forgotPassword.success){
        history.push('/auth/change-password-confim')
    }else{
        window.location.reload();
    }
}

const cognitoForgotPassword = async (username: string) => {
    const forgotPassword: ISuccess = {"success": null, "error": null};
    try {
        const success = await Auth.forgotPassword(username);
        forgotPassword.success = success;
    }catch(error){
        let err = null;
        !error.message ? err = {'message': error} : err = error;
        forgotPassword.error = err;
    }
    return forgotPassword;

}

const cognitoForgotPasswordSubmit = async (username: string, code: string, password: string) => {
    const forgotPassword: ISuccess = {"success": null, "error": null};
    try {
        const success = await Auth.forgotPasswordSubmit(username, code, password);
        forgotPassword.success = success;
    }catch(error){
        let err = null;
        !error.message ? err = {'message': error} : err = error;
        forgotPassword.error = err;
    }
    return forgotPassword;
}