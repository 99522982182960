import { history } from '../history';
import { Auth } from 'aws-amplify';

export const handleLogOut = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  e.preventDefault();
  const loginInfo = await cognitohandleLogOut();
  if (loginInfo.user) {
    history.push('/home')
  } else {
    window.location.reload();
  }
}

const cognitohandleLogOut = async () => {
  const loginInfo = {'user': null, 'error': null}
  try {
    const user = await Auth.signOut();
    loginInfo.user = user
  }catch(error){
    let err = null;
    !error.message ? err = {'message': error} : err = error;
    loginInfo.error = err;
  }
  return loginInfo;
}