import React from 'react';
import { IMessage } from '../../../interfaces/Message';
import { makeStyles, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px'
  },
  header: {
    marginBottom: '10px'
  },
  message: {
    marginTop: 20
  },
  messageTitle: {

  },
  messageText: {

  }
}))


interface CurrentMessagesProps {
  messages: IMessage[]
}

export const CurrentMessages: React.FC<CurrentMessagesProps> = (props) => {
  const classes = useStyles();
  const {messages} = props
  return(
    <div className={classes.root}>
      <Typography variant={"h1"} className={classes.header}>Current Messages</Typography>
      <Divider />
      <Typography variant={"h3"} className={classes.message}>Welcome Message</Typography>
      <Typography variant={"h5"}>{messages.find(m => m.default && m.message_type === "WELCOME")?.name}</Typography>
      <Typography>{messages.find(m => m.default && m.message_type === "WELCOME")?.text}</Typography>
      <Divider />
      <Typography variant={"h3"} className={classes.message}>Goodbye Message</Typography>
      <Typography variant={"h5"}>{messages.find(m => m.default && m.message_type === "GOODBYE")?.name}</Typography>
      <Typography>{messages.find(m => m.default && m.message_type === "GOODBYE")?.text}</Typography>
    </div>
  )
}