import React, {useState, ChangeEvent} from 'react';

function useForm<T>(intialValues: T) {
  const [values, setValues] = useState(intialValues);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  return {values, handleChange}
}

export default useForm;

export function useMaterialForm<T>(intialValues: T) {
  const [values, setValues] = useState(intialValues);

  const handleChange = (e: ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    if (e.target.name) {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      })  
    }
  }

  return {values, handleChange}
}