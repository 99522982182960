import React, {useState} from 'react';
import { CreateMessage } from './CreateMessage';
import { CurrentMessages } from './CurrentMessages';
// import { MessageArchive } from './MessageArchive';
import useFetch from '../../hooks/useFetch';
import Loader from '../../components/common/Loader';
import { IMessage } from '../../interfaces/Message';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px'
  }
}))

export const Messages: React.FC = () => {
  const classes = useStyles();
  const {data, loading, refresh } = useFetch<IMessage>('/messages')
  const [ create, setCreate ] = useState(false)

  const updateCreateStatus = (shouldRefresh?: boolean) => {
    setCreate(!create)
    if (refresh && shouldRefresh) {
      refresh()
    }
  }

  return (
    <div className={classes.root}>
      {
        loading ?
        <Loader /> :
        <div>
          {
            create
            ? <div>
                <Button variant="contained" color="primary" onClick={e => updateCreateStatus()}>Go Back</Button>
                <CreateMessage completed={updateCreateStatus} />
              </div>
            : <div>
                <Button variant="contained" color="primary" onClick={e => updateCreateStatus()}>Create New Message</Button>
                <CurrentMessages messages={data}/>
                {/* <MessageArchive messages={data}/> */}
              </div>
          }
        </div>
      }
    </div>
  )
}