import { ILoginForm } from "../../interfaces/Forms";
import { history } from '../history';
import { Auth } from 'aws-amplify';
import { AuthUser } from "../../types";

export const handleLogin = async (e: React.FormEvent<HTMLFormElement>, values: ILoginForm) => {
  e.preventDefault();
  const {username, password, newPassword} = values;
  const loginInfo = await cognitoLogin(username, password);
  if (loginInfo.user) {
    switch (loginInfo.user.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        if (!newPassword){
          loginInfo.error = {'message': 'reset_password'}
          return loginInfo.error  
        }else{
          await Auth.completeNewPassword(loginInfo.user, newPassword, {})
          history.push('/home')
          break;
        }
      default:
        history.push('/home')
    }
  } else {
    return loginInfo.error
  }
}

const cognitoLogin = async (username: string, password: string) => {
  const loginInfo: ILogin = {'user': null, 'error': null}
  try {
    const user = await Auth.signIn(username, password);
    loginInfo.user = user as AuthUser
  }catch(error){
    let err = null;
    !error.message ? err = {'message': error} : err = error;
    loginInfo.error = err;
  }
  return loginInfo;
}

interface ILogin {
  user: AuthUser | null,
  error: any
}

