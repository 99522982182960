import { createStyles, Theme } from "@material-ui/core";

export const formStyles = (theme: Theme) => createStyles({
    root: {
      padding: theme.spacing(4),
      margin: '0 auto',
      width: '300px',
      textAlign: 'left',
    },
    label: {
      lineHeight: '42px',
      textAlign: 'left',
      textIndent: '45px',
      width:'100%',
      transform: 'translate(0, -50%)'
    },
    input: {
      borderRadius: '3px',
      border: '1px solid',
      borderColor: theme.palette.grey[400],
      backgroundRepeat: 'no-repeat',
      backgroundSize: '15px',
      backgroundPosition: '5px',
      backgroundColor: '#fff',
      width: '100%',
      height: '45px',
      padding: '0 0 0 10px',
      fontFamily: 'Ariel, sans-serif',
      color: '#1f1f1f',
      fontSize: '16px',
      boxSizing: 'border-box'
    },
    submitButton: {
      display: 'inline-block',
      borderRadius: '3px',
      width: '100%',
      height: '56px',
      padding: '0 20px',
      textAlign: 'center',
      boxSizing: 'border-box',
      marginTop: theme.spacing(4),
      background: theme.palette.primary.main,
      color: '#fff',
      fontWeight: "bolder",
      fontSize: '20px',
      fontFamily: 'Ariel, sans-serif'
    }
  });