import React, { ReactChild } from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';

import Topbar  from './components/Topbar';

const styles = () => createStyles({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
});

interface MinimalProps extends WithStyles<typeof styles>{
  children: ReactChild,
  className?: string
}

const MinimalLayout: React.FC<MinimalProps> = (props) => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default withStyles(styles)(MinimalLayout);
