import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

interface RouteWithLayoutProps extends RouteProps {
    layout: any
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  }

const RouteWithLayout: React.FC<RouteWithLayoutProps> = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={matchProps=> (
                <Layout>
                    <Component {...matchProps} />
                </Layout>
            )}
        />
    )
}

export default RouteWithLayout;