import React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3)
  }
});

interface HomeProps extends WithStyles<typeof styles>{
  className?: string,
}

const Home: React.FC<HomeProps> = (props) => {
  const { classes } = props
  return (
    <div className={classes.root}>
        <Typography>Home</Typography>
    </div>
  )
}

export default withStyles(styles)(Home);