import React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1)
  }
});

interface NotFoundProps extends WithStyles<typeof styles>{
  className?: string,
}

const NotFound: React.FC<NotFoundProps> = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
        <Typography>Not Found...</Typography>
    </div>
  )
}

export default withStyles(styles)(NotFound);