import React from 'react';
import { WithStyles, withStyles, Typography } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { IForgotPasswordSubmitForm } from '../../interfaces/Forms';
import { handleForgotPasswordSubmit } from '../../helpers/auth/handleForgotPassword';
import { formStyles } from '../../assets/ts-styles/form';


interface ForgotPasswordVerificaitonProps extends WithStyles<typeof formStyles>{
  className?: string,
}

const ForgotPasswordVerificaiton: React.FC<ForgotPasswordVerificaitonProps> = (props) => {
  const {values, handleChange} = useForm<IForgotPasswordSubmitForm>({username:'', code: '', password:''})
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography variant='h2'>Verify Account</Typography>
      <Typography variant='body1'>
        Check your email for a verification code to reset your password.
      </Typography>
      <form onSubmit={e=> handleForgotPasswordSubmit(e, values)}>
        <label className={classes.label}>Code</label>
        <input
          className={classes.input}
          name="code"
          value={values.code}
          onChange={handleChange}
        />
        <label className={classes.label}>Username</label>
        <input
          className={classes.input}
          name="username"
          value={values.username}
          onChange={handleChange}
        />
        <label className={classes.label}>New Password</label>
        <input
          className={classes.input}
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
        />
        <input className={classes.submitButton} type="submit" value="Reset Password" />
      </form>
    </div>
  )
}

export default withStyles(formStyles)(ForgotPasswordVerificaiton);