import React, { useEffect, useState, useRef } from 'react';
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import { AWS } from '@aws-amplify/core';
import { AuthUser } from '../../types';
import theme from '../../theme';

interface AuthRouteWithLayoutProps extends RouteProps {
    layout: any
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    role?: string
  }

// const refreshCredentials = async (user: AuthUser) => {
//   const session = await user.getSession(() => {})
//   const refresh_token = session.getRefreshToken();
//   const config = AWS.config
//   const credentials = config.credentials
//   if (credentials) {
//     if (credentials.needsRefresh()) {
//       user.refreshSession(refresh_token, (err, session) => {
//       if(err) {
//         console.log(err);
//       } 
//       else {
//         credentials.params.Logins['cognito-idp.<YOUR-REGION>.amazonaws.com/<YOUR_USER_POOL_ID>']  = session.getIdToken().getJwtToken();
//         credentials.refresh((err)=> {
//           if(err)  {
//             console.log(err);
//           }
//           else{
//             console.log("TOKEN SUCCESSFULLY UPDATED");
//           }
//         });
//       }
//     });
//     }
//   }
// }

const AuthRouteWithLayout: React.FC<AuthRouteWithLayoutProps> = (props) => {
    const isCurrent = useRef(true);
    const { layout: Layout, component: Component, role, ...rest } = props;
    const [user, setUser] = useState<AuthUser | undefined | null>(undefined);

    useEffect(() => {
      return () => {
        isCurrent.current = false;
      }
    }, [])

    useEffect(() => {
      (async function getUser() {
          try{
              const session = await Auth.currentSession();
              const groups = session.getAccessToken().decodePayload()['cognito:groups'];
              const user: AuthUser = await Auth.currentAuthenticatedUser() as AuthUser;
              user.groups = groups;
              if (isCurrent.current){
                setUser(user);
              }
          }catch(error){
              if (error !== 'No current user') {
                  console.log(error);
              }
              setUser(null);
          }
      })();
      return () => setUser(undefined);
    }, []);


    let route = <div></div>
    if (user === undefined){
      route = <Layout>
        <div style={{padding: theme.spacing(3)}}>Loading...</div>
      </Layout>
    } else if (user) {
      if (role) {
        if(!user.groups?.includes(role)){
          route = <Redirect to={'/not-found'}/>
        }
      }
      route = <Route
        {...rest}
        render={(matchProps: any) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    } else {
      route = <Redirect to={'/auth/login'}/>
    }


    return (
      <React.Fragment>
        {route}
      </React.Fragment>
    )
}

export default AuthRouteWithLayout;