import { createMuiTheme, Theme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';


const themeOptions: ThemeOptions = {
  palette: palette,
  typography: typography,
  overrides: overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
}



const theme: Theme = createMuiTheme(themeOptions);

export default theme;
