import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Divider, Drawer, Theme } from '@material-ui/core';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import SettingsIcon from '@material-ui/icons/Settings';
import MessageIcon from '@material-ui/icons/Message';

import { Profile, SidebarNav } from './sidebarComponents';
import { Auth } from 'aws-amplify';
import { AuthUser } from '../../../types';


const styles = (theme: Theme) => createStyles({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
});

interface SidebarProps extends WithStyles<typeof styles>{
  className?: string,
  onClose?: () => void,
  open: boolean,
  variant: 'permanent' | 'persistent' | 'temporary'
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { classes, open, variant, onClose, className, ...rest } = props;
  const [user, setUser] = useState<AuthUser | undefined | null>(undefined);

  useEffect(() => {
    (async function getUser() {
        try{
            const session = await Auth.currentSession();
            const groups = session.getAccessToken().decodePayload()['cognito:groups'];
            const user: AuthUser = await Auth.currentAuthenticatedUser() as AuthUser;
            user.groups = groups;
            setUser(user);
        }catch(error){
            if (error !== 'No current user') {
                console.log(error);
            }
            setUser(null);
        }
    })();
    return () => {
      setUser(undefined);
    }
  }, []);

  const pages = [
    // {
    //   title: 'Dashboard',
    //   href: '/home',
    //   icon: <DashboardIcon />
    // },
    {
      title: 'Messages',
      href: '/messages',
      icon: <MessageIcon />
    },
    // {
    //   title: 'Configuration',
    //   href: '/admin',
    //   icon: <SettingsIcon />
    // },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile user={user}/>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};


export default withStyles(styles)(Sidebar);
