import React from 'react';
import { WithStyles, withStyles, Typography } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { IForgotPasswordForm } from '../../interfaces/Forms';
import { handleForgotPassword } from '../../helpers/auth/handleForgotPassword';
import { formStyles } from '../../assets/ts-styles/form';


interface LoginProps extends WithStyles<typeof formStyles>{
  className?: string,
}

const Login: React.FC<LoginProps> = (props) => {
  const {values, handleChange} = useForm<IForgotPasswordForm>({username:''})
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography variant='h2'>Forgot Password</Typography>
      <form onSubmit={e=> handleForgotPassword(e, values)}>
        <label className={classes.label}>Username</label>
        <input
          className={classes.input}
          name="username"
          value={values.username}
          onChange={handleChange}
        />
        <input className={classes.submitButton} type="submit" value="Submit" />
      </form>
    </div>
  )
}

export default withStyles(formStyles)(Login);