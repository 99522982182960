import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Theme } from '@material-ui/core';
import { IPage } from '../../../../interfaces';


const styles = (theme: Theme) => createStyles({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    // color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
});

interface SidebarNavProps extends WithStyles<typeof styles>{
  className?: string,
  pages: Array<IPage>
}


// const CustomRouterLink = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     style={{ flexGrow: 1 }}
//   >
//     <RouterLink {...props} />
//   </div>
// ));

const SidebarNav: React.FC<SidebarNavProps> = (props) => {
  const { classes, className, ...rest } = props;
  const {pages} = props

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map((page: any) => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

export default withStyles(styles)(SidebarNav);
