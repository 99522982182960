import React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4)
  }
});

interface ChangePasswordConfirmProps extends WithStyles<typeof styles>{
  className?: string,
}

const ChangePasswordConfirm: React.FC<ChangePasswordConfirmProps> = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <Typography variant='h2'>Password Changed</Typography>
    </div>
  )
}

export default withStyles(styles)(ChangePasswordConfirm);